import React, { Component } from 'react';
import Card from '../components/card';
import Title from '../components/title';
import CtaBanner from "../components/ctaBanner";
import styles from '../styles/scss/templates/uses.module.scss';
import SEO from "../components/seo";
import SelectMany from '../components/selectMany';
import EventAnchor from '../components/eventAnchor';
import DropdownContent from '../components/dropdownContent';
import { buildURL } from "react-imgix";
import { supSub } from '../services/util'
import { map as _map, uniqBy as _uniqBy, sortBy as _sortBy, find as _find, slice as _slice } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component';
import NewLayout from '../layout/newLayout';

export default class Uses extends Component {

  pageSize = 8

  extractParams = (search) => {
    var params = {}

    if (search !== "") {
      params = JSON.parse('{"' + decodeURI(search.replace("?", "").replace(/&/g, "\",\"").replace(/=/g, "\":\"")) + '"}')
    }

    // get filter from url
    const theUseArticle = params.useFilter && params.useFilter !== "null" ? params.useFilter.split(',') : []
    return {
      theUseArticle
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) { 
    const { search } = nextProps.location
    this.updateState(search, 0);
  }

  updateState(search, currentPage = 0) {
    // extract params from url
    const params = this.extractParams(search)

    // filter the useArticles
    const filteredUseArticles = this.filterUseArticles(this.state.useArticles, params.theUseArticle);
    // page the products
    const pagedUseArticles = _slice(filteredUseArticles, currentPage * this.pageSize, (currentPage * this.pageSize) + this.pageSize);

    this.setState({
      currentPage,
      selectedFilter: params.theUseArticle,
      filteredUseArticles: filteredUseArticles,
      pagedUseArticles: currentPage === 0 ? pagedUseArticles : [...this.state.pagedUseArticles, ...pagedUseArticles],
      pages: Math.ceil(filteredUseArticles.length / this.pageSize),
    })
  }

  constructor(props, context) {
    super(props, context)

    this.fetchData = this.fetchData.bind(this);

    // remove use articles that should not appear on index
    // let useArticles = props.pageContext.useArticles;
    let useArticles = props.pageContext.useArticles.filter((p) => {
      return p.showOnIndex === true
    })
    const { search } = props.location;
    const currentPage = 0;

    // extract params from url
    const params = this.extractParams(search)
    const filteredUseArticles = this.filterUseArticles(useArticles, params.theUseArticle)
    // paginate products
    const pagedUseArticles = _slice(filteredUseArticles, currentPage * this.pageSize, (currentPage * this.pageSize) + this.pageSize)

    this.state = {
      useArticles: useArticles,
      currentPage,
      selectedFilter: params.theUseArticle,
      filteredUseArticles: filteredUseArticles,
      pagedUseArticles: pagedUseArticles,
      pages: Math.ceil(filteredUseArticles.length / this.pageSize),
    }
  }

  selectFilter = (selected) => {
    this.setState({
      currentPage: 0,
      selectedFilter: selected
    }, () => {
      this.go(this.state.currentPage, this.state.selectedFilter)
    })
  }

  filterUseArticles = (useArticles, selectedFilter) => {
    let filteredUseArticles = useArticles

    filteredUseArticles = filteredUseArticles.filter((item) => {
      var matchFilter = true;

      if (selectedFilter.length) {
        matchFilter = _find(item.filters, (theUseArticle) => {
          return selectedFilter.includes(theUseArticle.slug)
        }) !== undefined
      }

      return matchFilter
    })
    return filteredUseArticles
  }

  changePage = (bool, changePage) => {
    var currentPage = this.state.currentPage
    if (changePage !== undefined) {
      currentPage = changePage - 1
    }
    this.go(currentPage, this.state.selectedFilter);
  }

  go = async (page, useFilter) => {
    const { pathname } = this.props.location;
    var params = [];
    if (page > 0) {
      params.push(`page=${page}`)
    }
    if (useFilter.length) {
      params.push(`useFilter=${useFilter.join(',')}`)
    }
    await window.history.pushState({}, '', `${pathname}` + (params.length > 0 ? "?" : "") + params.join('&'))

    const { search } = window.location
    this.updateState(search);
  }

  clearAllFilters = () => {
    this.selectFilter([]);
  }

  fetchData = () => {
    const page = this.state.currentPage + 1;
    const { search } = window ? window.location : this.props.location;
    this.updateState(search, page)
  }

  render() {
    var pg = this.props.pageContext.page;
    const [{ subtitle, body }] = pg.content;

    const pageSubtitle = supSub(subtitle);
    const filters = _map(_sortBy(_uniqBy([].concat.apply([], _map(this.state.useArticles, 'filters')), (item) => {
      return item.id
    }), (item) => {
      return item.order
    }), (item) => {
      return {
        value: item.slug,
        text: item.title
      }
    })

    return (
      <NewLayout>
        {this.props.pageContext.page.seo.title && <h1 style={{ display: 'none' }}>{this.props.pageContext.page.seo.title}</h1>}
        <SEO page={this.props.pageContext.page} />
        <div className={styles.mainContainerNewLayout}>
          <div className={styles.leftContainerNewLayout}>
            <div className={styles.topContentContainer}>
              <div className={styles.leftContent}>
                {pageSubtitle && <Title style={{ textTransform: 'capitalized' }} className={styles.sitetitleNewLayout} value={pageSubtitle} />}
                {body && <div className={styles.bodyNewLayout} dangerouslySetInnerHTML={{ __html: supSub(body) }} />}
              </div>
              <div
                className={styles.selectOptionsNew}>
                <DropdownContent title="Filter by" className='noPadding'>
                  <SelectMany name='Filter' values={filters} selected={this.state.selectedFilter} onChange={this.selectFilter} xxl={4} xl={4} />
                  <EventAnchor event={this.clearAllFilters} text="Clear all filters >>" condensed />
                </DropdownContent>
              </div>

            </div>

            <InfiniteScroll
              className={styles.infiniteContainer}
              dataLength={this.state.pagedUseArticles.length}
              hasMore={this.state.currentPage <= (this.state.pages - 1) ? true : false}
              loader={() => (
                <h4>
                  Loading...
                </h4>
              )}>

              <div className={styles.leftContainerNewLayout_cards}>
                <div className={styles.leftContainerNewLayout_cards_items}>
                  {this.state.pagedUseArticles && this.state.pagedUseArticles.map((item, index) => (
                    <Card key={index} link={`/useArticle/${item.slug}`} type={'detailed'} title={item.title} image={buildURL(item.heroArticle.url)}>
                      <p className={styles.cardDetailedText}>{item.teaser}</p>
                    </Card>
                  ))}

                  {this.state.currentPage < (this.state.pages - 1) &&
                    <div className={styles.seeMoreAnchor}>
                      <EventAnchor
                        text="See more Uses >>"
                        event={this.fetchData}
                      />
                    </div>
                  }
                </div>
              </div>
            </InfiniteScroll>
          </div>
        </div>
        <CtaBanner text="WANT TO GET MORE TIPS AND TRICKS?" buttonText="SUBSCRIBE TO THE NEWSLETTER" buttonUrl="/signup/" />
      </NewLayout>
    );
  }
}